import React, {useEffect, useState} from 'react';
import Page from '../Page';
import Head from './Head/Head';
import {useLounge} from '../../../../hooks/useLounge';
import {firebaseApp} from '../../../../firebase';
import styles from './Heads.module.css';

function Heads() {
    const {lounge} = useLounge();
    const [heads, setHeads] = useState([]);

    function loadHeads() {
        if (!lounge) {
            setHeads([]);
            return;
        }

        return firebaseApp.firestore()
            .collection(`heads`)
            .where('lounge', '==', lounge.id)
            .onSnapshot((querySnapshot) => {
                const docs = querySnapshot.docs
                    .map((docSnapshot) => ({
                        id: docSnapshot.id,
                        ...docSnapshot.data()
                    }))
                    .map((doc) => ({
                        ...doc,
                        coilMinutes: doc.coilSinceTotalMinutes ? (doc.totalMinutes - doc.coilSinceTotalMinutes) : doc.totalMinutes
                    }))
                    .sort((a, b) => b.coilMinutes - a.coilMinutes);

                setHeads(docs);
            });
    }

    useEffect(loadHeads, [lounge]);

    return (
        <Page
            title="Heads"
            actions={(handleClose) => []}
            content={<div className={styles.container}>
                {heads.map((head) => <Head key={head.id} head={head} />)}
            </div>
            }
        />
    );
}

export default Heads;
