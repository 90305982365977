import React from 'react';
import styles from './App.module.css';
import {AuthStatus, useAuth} from './hooks/useAuth';
import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Splash from './components/Splash/Splash';
import UpdateNotification from './components/UpdateNotification/UpdateNotification';

export const dateFormat = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric'
});

function contentFor(status) {
    switch (status) {
        case AuthStatus.LOGGED_IN:
            return (<Dashboard/>);
        case AuthStatus.LOADING:
            return (<Splash/>);
        default:
            return AuthStatus.ANONYMOUS = (<Login/>);
    }
}

function App() {
    const {status} = useAuth();

    return (
        <div className={styles.app}>
            {contentFor(status)}
            <UpdateNotification/>
        </div>
    );
}

export default App;
