import {useEffect, useMemo, useState} from 'react';

function usePagination(list, perPage) {
    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [fullList, setFullList] = useState([]);

    useEffect(() => {
        setPage(1);

        const length = list ? list.length : 0;

        setLastPage(Math.ceil(length / perPage));

        setFullList(list);
    }, [list, perPage]);

    const isLastPage = useMemo(() => page >= lastPage, [page, lastPage]);
    const isFirstPage = useMemo(() => page <= 1, [page]);

    function next() {
        if (!isLastPage) {
            setPage(page + 1);
        }
    }

    function previous() {
        if (!isFirstPage) {
            setPage(page - 1);
        }
    }

    const pageList = useMemo(extractPage, [fullList, page, list.length, perPage]);

    function extractPage() {
        const first = (page - 1) * perPage;
        const last = Math.min(list.length, (page * perPage));

        return fullList.slice(first, last);
    }

    return {
        previous: previous,
        next: next,
        isFirstPage: isFirstPage,
        isLastPage: isLastPage,
        pageList: pageList
    };
}

export {usePagination}
