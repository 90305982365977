import React, {createContext, useContext, useEffect, useState} from 'react';
import {firebaseApp, documentId} from '../firebase';
import 'firebase/compat/auth';
import {useAuth} from "./useAuth";

const LOUNGE = "hookamonk.lounge";

const defaultLounge = {
    id: null,
    name: null
};

const loungeContext = createContext({
    lounge: defaultLounge,
    lounges: [defaultLounge],
    onLoungeChange: () => {
    }
});

const useLounge = () => useContext(loungeContext);

function LoungeProvider({children}) {
    const {user} = useAuth();
    const [lounge, setLounge] = useState(defaultLounge);
    const [lounges, setLounges] = useState([]);

    function selectLounge(nextLounges, selected) {
        if (!nextLounges) {
            return defaultLounge;
        }

        const matchingLounge = selected ? nextLounges.find(candidate => candidate.id === selected) : null;

        return matchingLounge ? matchingLounge : nextLounges[0];
    }

    function subscribe() {
        let user_lounges = user.lounges || ['XXX'];
        const lounges_col = firebaseApp.firestore().collection('lounges');
        const query = user.fleetAdmin ? lounges_col : lounges_col.where(documentId(), "in", user_lounges);

        query.get()
            .then(
                (snapshot) => {
                    const nextLounges = snapshot.docs
                        .map((docSnapshot) => {
                            return {
                                id: docSnapshot.id,
                                ...docSnapshot.data()
                            }
                        });

                    const selectedLounge = lounge?.id ? lounge.id : localStorage.getItem(LOUNGE);

                    const nextLounge = selectLounge(nextLounges, selectedLounge);

                    setLounges(
                        nextLounges.map(
                            item => ({
                                ...item,
                                isLast: item.id === nextLounges[nextLounges.length - 1].id
                            })
                        )
                    );

                    setLounge(nextLounge);
                }
            );
    }

    function onLoungeChange(nextLounge) {
        setLounge(nextLounge);
        localStorage.setItem(LOUNGE, nextLounge?.id)
    }

    useEffect(subscribe, [lounge?.id]);

    return <loungeContext.Provider value={{
        lounge: lounge,
        lounges: lounges,
        onLoungeChange: onLoungeChange
    }}>{children}</loungeContext.Provider>;
}

export {useLounge, LoungeProvider}
