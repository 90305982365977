import React, {useState} from 'react';
import 'firebase/firestore';
import Button from '@material-ui/core/Button';
import styles from './HookahDetail.module.css';
import Dialog from '@material-ui/core/Dialog';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {ThemeProvider} from '@material-ui/core/styles';
import {firebaseApp} from '../../../../../firebase';
import axios from 'axios';
import firebase from 'firebase/compat/app';
import Select from "react-select";

function HookahDetail({hookah, session, lounge, onCancel, open, onClose, onSelectTobacco}) {
    const redTheme = createMuiTheme({
        palette: {
            primary: {
                main: 'hsl(0, 100%, 67%)'
            }
        }
    })

    const greenTheme = createMuiTheme({
        palette: {
            primary: {
                main: 'hsl(120%, 100%, 50%)'
            }
        }
    })

    const [processingSession, setProcessingSession] = useState(false);

    const CreateSessionUrl = 'https://us-central1-session-monk.cloudfunctions.net/create_server_session';

    const sessionId = hookah.session ? hookah.session : "-";

    function onStopSession(event) {
        setProcessingSession(true);
        firebaseApp.firestore()
            .doc(`sessions/${hookah.session}`)
            .set({finished: true}, {merge: true})
            .catch(error => console.error(error))
            .finally(() => {
                    setProcessingSession(false);
                }
            );
    }

    async function onStartSession(event) {
        if (!processingSession) {
            console.log('Create session');
            console.log(JSON.stringify(hookah));
            setProcessingSession(true);
            try {
                const axiosInstance = await axios.create();
                await axiosInstance.post(CreateSessionUrl, {
                    data: {
                        head_name: hookah.head,
                        lounge: lounge.id,
                        rpi_name: hookah.id,
                    }
                });
            } finally {
                setProcessingSession(false);
            }
        }
    }

    async function updateTargetTemperature(increment = 1) {
        if (!processingSession) {
            console.log('Temperature control, increment ' + increment);
            setProcessingSession(true);
            try {
                await firebaseApp.firestore().collection('sessions').doc(sessionId).update({
                    target_temperature_set: firebase.firestore.FieldValue.increment(increment)
                }).finally(() => {
                    setProcessingSession(false);
                });
            } finally {
                setProcessingSession(false);
            }
        }
    }


    function createSessionControlButton() {
        let actionButton = undefined;
        if (processingSession) {
            actionButton = <ThemeProvider theme={greenTheme}>
                <Button variant="contained" color="primary">
                    <span className={styles.button}>Processing...</span>
                </Button>
            </ThemeProvider>;
        } else if (hookah.status.active) {
            actionButton = <ThemeProvider theme={redTheme}>
                <Button variant="contained" color="primary" onClick={onStopSession}>
                    <span className={styles.button}>Stop session</span>
                </Button>
            </ThemeProvider>;
        } else if (hookah.status.ready) {
            actionButton = <ThemeProvider theme={greenTheme}>
                <Button variant="contained" color="primary" onClick={onStartSession}>
                    <span className={styles.button}>Start session</span>
                </Button>
            </ThemeProvider>;
        }
        return actionButton;
    }


    function createTobaccoButton() {
        if (hookah.status.active) {
            return <Button variant="contained" color="primary" onClick={onSelectTobacco}>
                <span className={styles.button}>Select tobacco</span>
            </Button>
        }
    }

    function createTempControlButton(title, increment) {
        let theme = increment > 0 ? greenTheme : redTheme;
        if (hookah.status.active) {
            return <ThemeProvider theme={theme}>
                <Button variant="contained" color="primary" onClick={() => updateTargetTemperature(increment)}>
                    <span className={styles.button}>{title}</span>
                </Button>
            </ThemeProvider>
        }
    }

    const colourStyles = {
        control: (styles) => ({...styles, backgroundColor: "white"}),
        option: (styles) => {
            return {
                ...styles,
                color: "#000",
            };
        }
    };


    async function handleAdminChange(admin) {
        if (!processingSession) {
            console.log('Setting shisha master');
            setProcessingSession(true)
            try {
                await firebaseApp.firestore().collection('sessions').doc(sessionId).update({
                    shisha_master: admin
                }).finally(() => {
                    setProcessingSession(false);
                });
            } finally {
                setProcessingSession(false);
            }
        }
    }

    function formatTemperature() {
        return session?.current_temperature ? `Current Temp: ${session?.current_temperature}  Target Temp: ${session?.target_temperature}` : '-';
    }

    return (


        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="sm">
            <div className={styles.container}>
                <h2>{hookah.id}</h2>

                <div className={styles.buttonContainer}>
                    {createSessionControlButton()}
                    {hookah.status.active && <>
                        {createTobaccoButton()}
                        <div className={styles.subtitle}>Shisha Master</div>
                        <Select options={lounge.admins?.map((admin) => {
                            return {value: admin, label: admin}
                        })}
                                onChange={(selectedAdmin) => handleAdminChange(selectedAdmin.value)}
                                styles={colourStyles}
                                defaultValue={{label: session?.shisha_master, value: session?.shisha_master}}>
                        </Select>
                        <div className={styles.subtitle}>{formatTemperature()}</div>
                        <div className={styles.buttonContainerRow}>
                            <div className={styles.button_child1}>
                                {createTempControlButton("-", -1)}
                            </div>
                            <div className={styles.button_child2}>
                                {createTempControlButton("+", +1)}
                            </div>
                        </div>
                        <div className={styles.subtitle}>{`Session: ${sessionId}`}</div>
                    </>}
                </div>

                <div className={styles.actionsContainer}>
                    <hr/>

                    <div className={styles.actions}>
                        <Button onClick={onCancel} variant="text">Cancel</Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default HookahDetail;
