import React from 'react';
import styles from './Head.module.css';
import animations from '../../../../animations/animations.module.css';
import 'firebase/firestore';
import 'firebase/functions';
import LinearProgress from '@material-ui/core/LinearProgress';
import {SvgIcon} from '@material-ui/core';
import {Brightness1Rounded, SyncProblemRounded} from '@material-ui/icons';
import {dateFormat} from '../../../../../App';

const COIL_LIFESPAN_MINUTES = 2000;

function Head({head}) {
    const needsService = head.coilMinutes > COIL_LIFESPAN_MINUTES;
    const stillGood = head.coilMinutes < 0.8*COIL_LIFESPAN_MINUTES;
    const lastSeen = head.lastSeen ? dateFormat.format(head.lastSeen.toDate()) : 'unknown'

    console.log(head.lastSeen && (new Date() - head.lastSeen.toDate()));

    // const outdated = head.lastSeen ?  : false;

    const barClass = needsService ? styles.endingBar
                     : stillGood ? styles.startingBar
                     : '';

    const barDimmedClass = needsService ? styles.endingBarDimmed
                     : stillGood ? styles.startingBarDimmed
                     : '';

    return (
        <div className={`${styles.container} ${needsService ? styles.warningContainer : ''}`}>
            <div className={styles.header}>
                {head.id}
            </div>
            <div className={styles.content}>
                <div className={styles.counter}>
                    <div className={styles.statusLeft}>
                        {needsService && <SvgIcon component={SyncProblemRounded} classes={{ root: styles.needsService }} />}
                    </div>
                    <div className={styles.statusRight}>
                        {head.sessionId && <SvgIcon component={Brightness1Rounded} classes={{ root: `${styles.active} ${animations.active}` }} />}
                    </div>
                    <div className={styles.label}>
                        coil time
                    </div>
                    <div className={styles.minutes}>
                        <span className={`${styles.mainCount} ${styles.value} ${needsService ? styles.needsService : ''}`}>{head.coilMinutes}</span> / {COIL_LIFESPAN_MINUTES} min
                    </div>
                    <LinearProgress variant="determinate" value={Math.min(100, (100.0 * head.coilMinutes) / COIL_LIFESPAN_MINUTES)} classes={{
                        root: styles.progress,
                        colorPrimary: barDimmedClass,
                        barColorPrimary: barClass
                    }} />
                </div>

                <div>
                    last seen <span className={styles.value}>{lastSeen}</span>
                </div>
            </div>
        </div>
    );
}

export default Head;
