import React, {useEffect, useMemo, useState} from 'react';
import styles from './Tables.module.css';
import Page from '../Page';
import {useLounge} from '../../../../hooks/useLounge';
import {firebaseApp} from '../../../../firebase';
import Table from './Table/Table';
import MenuItem from '@material-ui/core/MenuItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import {useAuth} from '../../../../hooks/useAuth';

import 'firebase/compat/firestore';
import axios from "axios";

const PREFERRED_SORT = "hookamonk.tables.sort";

function extractTableName(hookah) {
    const place = hookah.id.split('_', 3);
    return ((place.length > 1) ? place[1] : hookah.id);
}

function groupByTableName(hookahs) {
    if (!hookahs) {
        return [];
    }

    return hookahs.reduce((tables, hookah) => {
        const tableName = extractTableName(hookah);

        const tableHookahs = tables.get(tableName) || [];

        tables.set(tableName, [...tableHookahs, hookah]);

        return tables;
    }, new Map());
}

function mapHookah(hookah, sessions) {
    console.log('Mapping hookahs');
    const session = sessions.get(hookah.session);

    const active =  session != undefined;
    const started = session?.started?.toMillis();
    const ready = ! hookah.default; //head is not default

    return {
        ...hookah,
        status: {
            active: active, //active session
            ready: ready, //head is connected - warp can be started remotely
            started: started ? started : 0
        }
    }
}

function Tables() {
    const {user} = useAuth();
    const {lounge} = useLounge();

    const [hookahs, setHookahs] = useState(new Map())
    const [sessions, setSessions] = useState(new Map())
    const [sessionChanged, setSessionChanged] = useState(false);
    const [sort, setSort] = useState(localStorage.getItem(PREFERRED_SORT) || 'activity')

    function loadHookahs() {
        if (!lounge) {
            setHookahs(new Map());
            return;
        }

        let lounge_hookahs = firebaseApp.firestore().collection(`lounges/${lounge.id}/hookahs`);


        return lounge_hookahs.onSnapshot(
                async (querySnapshot) => {
                    const docs = querySnapshot.docs
                        .map((docSnapshot) => ({
                            id: docSnapshot.id,
                            ...docSnapshot.data()
                        }))

                    const onlineOnlyHookahs = await filterOnlineOnly(docs, lounge.id);

                    const groupedHookahs = groupByTableName(onlineOnlyHookahs);
                    setHookahs(groupedHookahs);
                }
            );
    }

    async function filterOnlineOnly(docs, loungeId) {
        console.log('Filtering online only');

        const balenaFetcher = await axios.create(
            {
                headers: {
                    common: {
                        authorization: 'Bearer rSHt75R3pR1FbYLWxMqHpGJlq5J49iEd',
                        'Content-Type': 'application/json'
                    }
                }
            }
        );
        const url = `https://api.balena-cloud.com/v6/device?\$filter=((is_online%20eq%20true)%20and%20(belongs_to__application%20eq%201535704)%20and%20(device_tag/any(dt:(((tag_key)%20eq%20(%27lounge%27))%20and%20((value)%20eq%20(%27${loungeId}%27))))))`;
        return balenaFetcher.get(url).then(response => {
            //find only online hookahs in Balena
            let retDocs = [];
            if(response.status === 200) {
                docs.forEach((hookah) => {
                    if (response.data.d.find(item => item.device_name === hookah.id)) {
                        retDocs.push(hookah);
                    }
                });
            }
            return retDocs;
        })
    }

    useEffect(loadHookahs, [lounge, user.fleetAdmin]);

    function loadSessions() {
        if (hookahs.length === 0) {
            return;
        }

        const sessionIds = new Set()

        for (const tableHookahs of hookahs.values()) {
            for (const hookah of tableHookahs) {
                hookah.session && sessionIds.add(hookah.session);
            }
        }

        const cleanups = Array.from(sessionIds).map(sessionId => firebaseApp.firestore()
            .doc(`sessions/${sessionId}`)
            .onSnapshot(
                (snapshot) => {
                    const doc = {
                        id: snapshot.id,
                        ...snapshot.data()
                    };
                    setSessions((previous) => previous.set(snapshot.id, doc));
                    setSessionChanged((prev) => !prev);
                }
            )
        );

        return () => cleanups.forEach(it => it());
    }

    useEffect(loadSessions, [hookahs]);

    const tables = useMemo(() => {
        return Array.from(hookahs.entries()).map(([table, tableHookahs]) => ({
                name: table,
                hookahs: tableHookahs.map(hookah => mapHookah(hookah, sessions))
            })).sort((a, b) => {
                if (sort === 'activity') {
                    function topActivity(hookahs) {
                        return {
                            active: hookahs.some(it => it.status.active),
                            started: hookahs.reduce((min, it) => Math.min(min, it.status.started), Date.now())
                        }
                    }

                    const aOrder = topActivity(a.hookahs);
                    const bOrder = topActivity(b.hookahs);


                    if (aOrder.active !== bOrder.active) {
                        return aOrder.active ? -1 : +1;
                    }

                    return aOrder.started - bOrder.started;
                } else if (sort === 'alphabet') {

                    return b.name < a.name ? +1 : -1;
                } else {
                    return 0;
                }
            })
    }, [sessionChanged, hookahs, sort])

    function changeSort(nextSort, handleClose) {
        setSort(nextSort);
        localStorage.setItem(PREFERRED_SORT, nextSort);
        handleClose();
    }

    return (
        <Page
            title="Tables"
            actions={(handleClose) => [
                (<ListSubheader key="label-table">Table order</ListSubheader>),
                (<MenuItem key="activity" onClick={() => changeSort('activity', handleClose)}>
                    <span className={(sort === 'activity') ? styles.current : ''}>by activity</span>
                </MenuItem>),
                (<MenuItem key="alphabet" onClick={() => changeSort('alphabet', handleClose)}>
                    <span className={(sort === 'alphabet') ? styles.current : ''}>by table name</span>
                </MenuItem>)
            ]}
            content={(
                <div className={styles.container}>
                    {tables.map(table => <Table key={table.name} table={table}/>)}
                </div>
            )}
        />
    );
}

export default Tables;
