import React from 'react';
import logo from '../../assets/hookamonk.svg';
import styles from './Splash.module.css';
import animations from '../animations/animations.module.css';

function Splash() {
    return (
        <div className={styles.center}>
            <div className={styles.container}>
                <img className={[styles.logo, animations.glowing].join(" ")} src={logo} alt="Hookamonk logo"/>
                <h2>Loading...</h2>
            </div>
        </div>
    );
}

export default Splash;
