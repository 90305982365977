import React from 'react';

import SvgIcon from '@material-ui/core/SvgIcon';
import {WifiOffRounded, WifiRounded} from '@material-ui/icons';
import styles from './NetworkStatus.module.css';

function NetworkStatus({online}) {
    return online ?
        (<SvgIcon component={WifiRounded} className={styles.connected} fontSize={'inherit'} />)
        : (<SvgIcon component={WifiOffRounded}  className={styles.disconnected} fontSize={'inherit'} />);
}

export default NetworkStatus;


