import React, {createContext, useContext, useEffect, useState, useMemo} from 'react';
import {Workbox, messageSW} from 'workbox-window';

const ServiceWorkerContext = createContext({});

function WorkboxProvider({children}) {
    const [workbox, setWorkbox] = useState(null);
    const [registration, setRegistration] = useState(null);
    const [isUpdateAvailable, setUpdateAvailable] = useState(false)

    const handleUpdateEvent = (event) => {
        console.log(`A new service worker is ${event.isUpdate ? '' : 'not'} available.`);
        console.debug(event);
        setUpdateAvailable(event.isUpdate);
    }

    useEffect(() => {
        if ('serviceWorker' in navigator) {
            const wb = new Workbox('/sw.js');

            setWorkbox(wb);

            wb.addEventListener('waiting', handleUpdateEvent);
            wb.addEventListener('externalwaiting', handleUpdateEvent);

            wb.register().then((r) => {
                setRegistration(r);
                console.log(`Service worker is registered.`);
            });
        }
    }, []);

    const value = useMemo(() => ({
        isUpdateAvailable,
        updateAssets: () => {
            if (registration) {
                setUpdateAvailable(false);

                workbox.addEventListener('controlling', (event) => {
                    console.log('Reloading page after service worker update.');
                    window.location.reload();
                });

                console.log('Notifying waiting service worker to proceed with update.');
                messageSW(registration.waiting, { type: 'SKIP_WAITING' });
            }
        }
    }), [isUpdateAvailable, workbox]);

    return (
        <ServiceWorkerContext.Provider value={value}>
            {children}
        </ServiceWorkerContext.Provider>
    );
}

const useServiceWorker = () => {
    return useContext(ServiceWorkerContext);
};

export  {useServiceWorker, WorkboxProvider }

