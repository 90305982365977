import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {ThemeProvider} from '@material-ui/core/styles';
import {AuthProvider} from './hooks/useAuth';
import {ClockProvider} from './hooks/useClock';
import {WorkboxProvider} from './hooks/useServiceWorker';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';

const theme = createMuiTheme({
    palette: {
        type: 'dark',
        primary: {
            main: '#eaa23e',
        },
        background: {
            paper: "#282830"
        }
    }
});

ReactDOM.render(
    <WorkboxProvider>
        <AuthProvider>
            <ClockProvider>
                <ThemeProvider theme={theme}>
                    <App/>
                </ThemeProvider>
            </ClockProvider>
        </AuthProvider>
    </WorkboxProvider>,
    document.getElementById('root'));
