import React, {useEffect, useMemo, useState} from 'react';
import Button from '@material-ui/core/Button';
import styles from './HookahStatus.module.css';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import {ThemeProvider} from '@material-ui/core/styles';
import {useLounge} from '../../../../../hooks/useLounge';
import TobaccoPicker from '../TobaccoPicker/TobaccoPicker';
import HookahDetail from '../HookahDetail/HookahDetail';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import {useClock} from "../../../../../hooks/useClock";
import {firebaseApp} from "../../../../../firebase";

function HookahStatus({hookah}) {
    const session = hookah.session;

    const clock = useClock();

    const {lounge} = useLounge();

    const [timeLeft, setTimeLeft] = useState();
    const [lastMinutes, setLastMinutes] = useState(false);
    const [sessionData, setSessionData] = useState({});

    const [hookahStatus, setHookahStatus] = useState(hookah.status);
    const [tobaccos, setTobaccos] = useState([]);
    const [tobaccoSelectionOpen, setTobaccoSelectionOpen] = useState(false);
    const [hookahDetailOpen, setHookahDetailOpen] = useState(false);

    useEffect(setHookahTimeStats, [clock, hookahStatus.started, hookahStatus.active, sessionData]);

    useEffect(listenToSessionChanges, [session]);

    function listenToSessionChanges() {
        if (session)
            firebaseApp.firestore()
                .doc(`sessions/${session}`)
                .onSnapshot(
                    (snapshot) => {
                        setSessionData(snapshot.data());
                    }
                )
    }

    useEffect(() => {
        if (!tobaccoSelectionOpen) {
            let newTobaccos = [];
            if (sessionData?.mix) {
                newTobaccos = Object.keys(sessionData.mix).map((key) => {
                    return sessionData.mix[key];
                })
            }
            setTobaccos(newTobaccos);
        }
    }, [sessionData]);


    useEffect(() => {
        setHookahStatus(hookah.status);
    }, [hookah]);


    function setHookahTimeStats() {
        const secondsElapsed = sessionData?.started ? Math.floor((Date.now() - sessionData.started.toMillis()) / 1000) : 0;
        const session_length_seconds = sessionData ? sessionData.session_length : 0;

        const left = hookahStatus.active ? Math.max(0, session_length_seconds - secondsElapsed) : 0;
        const lastMinutes = hookahStatus.active && secondsElapsed > (session_length_seconds - 2);

        setTimeLeft(left);
        setLastMinutes(lastMinutes);
    }

    function handleCloseSelectTobacco(event) {
        setTobaccoSelectionOpen(false);
    }

    function handleCloseHookahDetail(event) {
        setHookahDetailOpen(false);
    }


    const theme = useMemo(() => createMuiTheme({
        palette: {
            primary: {
                main: hookahStatus.active ?
                    `hsla(${Math.floor(120 * timeLeft / (75 * 60))}, 100%, 67%, 0.5)`
                    : "#ffffff"
            },
            action: {
                disabled: "#ffffff40",
                disabledBackground: "#ffffff20"
            }
        }
    }), [hookahStatus, timeLeft]);

    const buttonClass = useMemo(() => `${styles.container} ${lastMinutes ? styles.alert : ""}`, [lastMinutes])

    function onHookahClicked(event) {
        setHookahDetailOpen(true);
    }

    function openTobaccoSelection(event) {
        setHookahDetailOpen(false);
        setTobaccoSelectionOpen(true);
    }

    function formatTemperature() {
        return sessionData?.current_temperature ? `${sessionData?.current_temperature}/${sessionData?.target_temperature}` : '-';
    }

    function formatShishaMaster() {
        return sessionData?.shisha_master;
    }

    const buttonStyle = hookahStatus.active ? 'contained' : 'outlined'

    const title = () => {
        let formatTimeLeft = {
            hours: Math.floor(timeLeft / 3600),
            minutes: Math.floor(timeLeft % 3600 / 60),
            seconds: Math.floor(timeLeft % 3600 % 60)
        };
        let timeLeftFormatted = formatTimeLeft.hours > 0 ? `${formatTimeLeft.hours}:` : "";
        timeLeftFormatted += `${("0" + formatTimeLeft.minutes).slice(-2)}:${("0" + formatTimeLeft.seconds).slice(-2)}`

        return hookahStatus.active ? timeLeftFormatted : (hookahStatus.ready ? "Ready" : "Idle");
    }

    return (
        <div className={styles.container}>
            <ThemeProvider theme={theme}>
                <Button
                    variant={buttonStyle}
                    color="primary"
                    className={buttonClass}
                    onClick={onHookahClicked}>
                    <div className={styles.button}>
                        <div className={styles.status}>{title()}</div>
                        {hookahStatus.active && <>
                          {sessionData?.shisha_master &&  <div className={styles.subtitle}>{formatShishaMaster()}</div>}
                            <div className={styles.subtitle}>{formatTemperature()}</div>
                            {React.Children.toArray(tobaccos.map((tobacco) => {
                                return <div className={styles.subtitle}>{tobacco.label}</div>
                            }))}
                        </>}
                    </div>
                </Button>
            </ThemeProvider>
            <HookahDetail
                hookah={hookah}
                lounge={lounge}
                session={sessionData}
                onCancel={handleCloseHookahDetail}
                open={hookahDetailOpen}
                onClose={handleCloseHookahDetail}
                onSelectTobacco={openTobaccoSelection}/>
            <TobaccoPicker
                loungeId={lounge.id}
                currentMix={tobaccos}
                sessionId={session}
                onCancel={handleCloseSelectTobacco}
                open={tobaccoSelectionOpen}
                onClose={handleCloseSelectTobacco}/>
        </div>
);
}

export default HookahStatus;
