import React, {useEffect, useState} from 'react';
import 'firebase/firestore';
import Chip from '@material-ui/core/Chip';
import {firebaseApp} from '../../../../../firebase';
import {usePagination} from '../../../../../hooks/usePagination';
import Button from '@material-ui/core/Button';
import styles from './TobaccoPicker.module.css';
import {ArrowBackIosRounded, ArrowForwardIosRounded} from '@material-ui/icons';
import SvgIcon from '@material-ui/core/SvgIcon';
import Dialog from '@material-ui/core/Dialog';
import Select from 'react-select'
import {RadioGroup, RadioButton} from 'react-radio-buttons';


function TobaccoPicker({loungeId,currentMix,sessionId, onCancel, open, onClose}) {
    const [producers, setProducers] = useState([]);
    const [producer, setProducer] = useState(null);
    const [tobaccoPreferences, setTobaccoPreferences] = useState(({}));
    const [picked, setPicked] = useState(currentMix);
    const [tobaccos, setTobaccos] = useState([]);
    const [tobaccoPercentage, setTobaccoPercentage] = useState("10");
    const percentages = ["10", "20", "30", "40", "50", "60", "70", "80", "90", "100"];


    useEffect(() => {
        setPicked(currentMix);
    }, [currentMix]);

    useEffect(() => {
        let active = true;
        fetchTobaccos();
        return () => {
            active = false
        }

        async function fetchTobaccos() {
            let producerTobaccos = [];
            if (!producer) {
                return producerTobaccos;
            }
            await firebaseApp.firestore()
                .collection(`manufactures/${producer.name}/tobaccos`)
                .get()
                .then(
                    (querySnapshot) => {
                        producerTobaccos = querySnapshot.docs.map(docSnapshot => docSnapshot.id);
                    }
                );

            if (!active) {
                return
            }
            setTobaccos(producerTobaccos);
        }
    }, [producer]);

    const {next, previous, isFirstPage, isLastPage, pageList} = usePagination(tobaccos, 9);



    function fetchProducers() {
        firebaseApp.firestore()
            .collection('manufactures')
            .get()
            .then(
                (querySnapshot) => {
                    setProducers(querySnapshot.docs.map(docSnapshot => docSnapshot.data()));
                }
            );
    }

    useEffect(fetchProducers, []);

    function fetchTobaccoPreferences() {
        // firebaseApp.firestore()
        //     .collection(`lounges/${loungeId}/top_tobaccos`)
        //     .get()
        //     .then(
        //         (querySnapshot) => {
        //             let preferences = {};
        //
        //             querySnapshot.docs.forEach(docSnapshot => {
        //                 preferences[docSnapshot.id] = docSnapshot.data();
        //             });
        //
        //             setTobaccoPreferences(preferences);
        //         }
        //     );
    }

    useEffect(fetchTobaccoPreferences, [loungeId]);


    function handleAppend(tobacco) {
        const nextTobacco = {
            name: tobacco,
            producer: producer.name,
            percentage: tobaccoPercentage,
            label: `${producer.name} / ${tobacco} (${tobaccoPercentage} %)`,
        };

        if (!picked.find((item) => item.name === nextTobacco.name && item.producer === nextTobacco.producer)) {
            setPicked([...picked,nextTobacco]);
        }
        setProducer(null);
        setTobaccoPercentage('50');
    }

    async function onSave() {
        console.log('Storing mix to firestore');
        let mix_content = {};
        picked.forEach((tobacco, index) => {
            mix_content[index] = tobacco;
        });
        console.log(`New mix: ${JSON.stringify(mix_content)}`);

        await firebaseApp.firestore()
            .doc(`sessions/${sessionId}`)
            .update("mix", mix_content)
            .finally(onCancel());
    }

    function handleTobaccoDelete(tobacco) {
        setPicked(picked.filter(item => item.name !== tobacco.name || item.producer !== tobacco.producer));
    }

    function handleProducerChange(selectedOption) {
        setProducer(selectedOption.value);
    }

    const colourStyles = {
        control: (styles) => ({...styles, backgroundColor: "white"}),
        option: (styles) => {
            return {
                ...styles,
                color: "#000",
            };
        }
    };


    return (
        <Dialog open={open} onClose={onClose} fullWidth={true} maxWidth="xl" classes={{paper: styles.tobacooPicker}}>
            <div className={styles.container}>
                <h2>Fill in Hookah</h2>
                {(picked.length > 0) && <>
                    <div className={styles.chips}>
                        {picked.map((tobacco) =>
                            <Chip
                                key={tobacco.label}
                                label={tobacco.label}
                                color={'primary'}
                                onDelete={() => handleTobaccoDelete(tobacco)}/>)}
                    </div>
                    <hr/>
                </>}
                <h3>Producers</h3>
                <Select options={producers.map((producer) => {
                    return {value: producer, label: producer.name}
                })}
                        onChange={(selectedProducer) => handleProducerChange(selectedProducer)}
                        styles={colourStyles}>
                </Select>

                {
                    producer && <>
                        <hr/>

                        <div className={styles.titleWithPagination}>
                            <h3>Tobaccos</h3>

                            <div>
                                <Button variant="text" disabled={isFirstPage} onClick={previous}
                                        className="navigation-btn">
                                    <SvgIcon component={ArrowBackIosRounded}/>
                                </Button>
                                <Button variant="text" disabled={isLastPage} onClick={next} className="navigation-btn">
                                    <SvgIcon component={ArrowForwardIosRounded}/>
                                </Button>
                            </div>
                        </div>

                        <div className={styles.choices}>
                            {pageList.map(tobacco =>
                                <Button variant="outlined" key={tobacco} onClick={() => handleAppend(tobacco)}>
                                    {tobacco}
                                </Button>)}
                        </div>


                        <RadioGroup onChange={setTobaccoPercentage} horizontal value='50'>
                            {React.Children.toArray(percentages.map(percentage =>
                                <RadioButton value={percentage}>
                                    {`${percentage} %`}
                                </RadioButton>))
                            }
                        </RadioGroup>

                    </>
                }

                <div className={styles.actionsContainer}>
                    <hr/>

                    <div className={styles.actions}>
                        <Button onClick={onCancel} variant="text">Cancel</Button>
                        <Button onClick={onSave} variant="text">Save</Button>
                    </div>
                </div>
            </div>
        </Dialog>
    )
}

export default TobaccoPicker;
