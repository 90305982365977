import React, {useRef} from 'react';
import styles from './Page.module.css';
import Menu from '../Menu/Menu';

function Page(props) {
    const pageSection = useRef()

    return (
        <div className={styles.container}>
            <section id="menu" className={styles.menu}>
                <Menu actions={props.actions} title={props.title} />
            </section>
            <section id="page" ref={pageSection} className={`${styles.page}`}>
                {props.content}
            </section>
        </div>
    );
}

export default Page;
