import React, {createContext, useContext, useEffect, useState} from 'react';
import {firebaseApp} from '../firebase';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

const HOOKAMONK_USER = "hookamonk.user";
const FLEET_ADMIN_SUFFIXES = ['@hookamonk.com', '@topmonks.com'];

const AuthStatus = {
    ANONYMOUS: 1,
    LOADING: 2,
    LOGGED_IN: 3
}

const USER_LOADING = {
    user: null,
    status: AuthStatus.LOADING
};

const authContext = createContext(USER_LOADING);

const useAuth = () => {
    return useContext(authContext);
};

function AuthProvider({children}) {
    const isFleetAdmin = (user) => FLEET_ADMIN_SUFFIXES.some((suffix) => user?.email?.endsWith(suffix));

    const [auth, setAuth] = useState(() => {
            const userJson = localStorage.getItem(HOOKAMONK_USER);

            if (!userJson) {
                return USER_LOADING;
            }

            const user = JSON.parse(userJson);

            return {
                user: {
                    ...user,
                    fleetAdmin: isFleetAdmin(user)
                },
                status: AuthStatus.LOGGED_IN
            };
        }
    );

    function subscribe() {
        return firebaseApp.auth().onAuthStateChanged(async (fireBaseUser) => {
            const nextUser = fireBaseUser ? {
                displayName: fireBaseUser.displayName,
                photoURL: fireBaseUser.photoURL,
                email: fireBaseUser.email
            } : null;


            if (nextUser) {
                firebaseApp.firestore()
                    .doc(`users/${nextUser.email}`)
                    .onSnapshot(
                        (user) => {
                            nextUser.lounges = user.data()?.lounge_admin;
                            localStorage.setItem(HOOKAMONK_USER, JSON.stringify(nextUser));
                            setAuth({
                                user: {
                                    ...nextUser,
                                    fleetAdmin: isFleetAdmin(nextUser)
                                },
                                status: AuthStatus.LOGGED_IN
                            });
                        })
            } else {
                localStorage.removeItem(HOOKAMONK_USER);

                setAuth({
                    user: null,
                    status: AuthStatus.ANONYMOUS
                });
            }
        });
    }

    useEffect(subscribe, []);

    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}

function handleGoogleLogin() {
    const provider = new firebase.auth.GoogleAuthProvider();
    return firebaseApp.auth().signInWithRedirect(provider)
}

function handleAuthLogout() {
    return firebaseApp.auth().signOut();
}

export {useAuth, AuthProvider, handleGoogleLogin, handleAuthLogout, AuthStatus}
