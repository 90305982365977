import React from 'react';
import {Redirect, Router} from '@reach/router';
import {LoungeProvider} from '../../hooks/useLounge';
import Tables from './pages/Tables/Tables';
import Heads from './pages/Heads/Heads';
import styles from './Dashboard.module.css';

function Dashboard() {
    return (
        <LoungeProvider>
            <Router primary={false} className={styles.router}>
                <Tables path="/tables" />
                <Heads path="/heads" />
                <Redirect from="*" to="/tables" noThrow />
            </Router>
        </LoungeProvider>
    );
}

export default Dashboard;
