import React, {useRef, useState} from 'react';
import styles from './Menu.module.css';
import UserAvatar from './UserAvatar/UserAvatar';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import {AppsRounded, MemoryRounded, WeekendRounded} from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';
import {useLounge} from '../../../hooks/useLounge';
import {navigate} from '@reach/router';
import ListSubheader from '@material-ui/core/ListSubheader';

function Menu(props) {
    const [open, setOpen] = useState(false);
    const anchorRef = useRef(null);
    const {lounge} = useLounge();

    const handleOpen = () => setOpen(!open);
    const handleClose = () => setOpen(false);

    const handleKeyDown = (event) => {
        if (event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
            handleClose();
        }
    }

    const actionsContent = props.actions(handleClose);

    return (
        <>
            <div className={styles.container}>
                <IconButton onClick={handleOpen} ref={anchorRef}>
                    <SvgIcon component={AppsRounded} fontSize={'large'}/>
                </IconButton>
                <h1>{lounge?.name} <span className={styles.pageTitle}>{props.title}</span></h1>
                <UserAvatar/>
            </div>
            <Popper onKeyDown={handleKeyDown} open={open} anchorEl={anchorRef.current} placement={'bottom'} style={{marginTop: '2px'}} transition>
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grow {...TransitionProps}>
                            <Paper>
                                <MenuList autoFocusItem={true} id="page-menu-grow" subheader={<ListSubheader>Navigation</ListSubheader>}>
                                    <MenuItem key="nav-tables" onClick={() => { navigate('/tables'); handleClose(); }} autoFocus={true}>
                                        <div className={styles.menuItem}>
                                            <SvgIcon component={WeekendRounded} />
                                            <span>Tables</span>
                                        </div>
                                    </MenuItem>
                                    <MenuItem key="nav-heads" onClick={() => { navigate('/heads'); handleClose(); }} divider={actionsContent.length > 0}>
                                        <div className={styles.menuItem}>
                                            <SvgIcon component={MemoryRounded} />
                                            <span>Heads</span>
                                        </div>
                                    </MenuItem>
                                    {actionsContent}
                                </MenuList>
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}

export default Menu;
