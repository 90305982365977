import React from 'react';
import styles from './Table.module.css';
import HookahStatus from '../HookahStatus/HookahStatus';

function Table(props) {
    const name = props.table.name;
    const hookahs = props.table.hookahs;

    const className = `${styles.container} `;

    return (
        <div className={`${className}`} style={{gridRow: `span ${Math.max(2, hookahs.length + 1)}`}}>
            <div className={styles.header}>
                {name}
            </div>
            <div className={styles.content}>
                {hookahs.map(it => <HookahStatus key={it.id} hookah={it} />)}
            </div>
        </div>
    );
}

export default Table;
