import React, {useEffect, useRef, useState} from 'react';
import 'firebase/firestore';
import {handleAuthLogout, useAuth} from '../../../../hooks/useAuth';
import Avatar from '@material-ui/core/Avatar';
import MenuItem from '@material-ui/core/MenuItem';
import Popper from '@material-ui/core/Popper';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import MenuList from '@material-ui/core/MenuList';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import NetworkStatus from './NetworkStatus/NetworkStatus';
import styles from './UserAvatar.module.css';
import SvgIcon from '@material-ui/core/SvgIcon';
import {StoreRounded} from '@material-ui/icons';
import {useLounge} from '../../../../hooks/useLounge';

function UserAvatar() {
    const {user} = useAuth();

    const {lounge, lounges, onLoungeChange} = useLounge();

    const [online, setOnline] = useState(true);
    const [open, setOpen] = useState(false);

    const anchorRef = useRef(null);

    useEffect(() => {
        if (!open) {
            setLoungePickerOpen(false);
        }
    }, [open])

    const handleOpen = () => {
        setOpen(!open);
    }
    const handleClose = () => {
        setOpen(false);
    }
    const handleReload = () => window.location.reload();
    const handleLogout = async () => await handleAuthLogout().then(handleClose);
    const handleOnlineEvent = () => setOnline(true);
    const handleOfflineEvent = () => setOnline(false);

    useEffect(() => {
        window.addEventListener('online', handleOnlineEvent);
        window.addEventListener('offline', handleOfflineEvent);

        return () => {
            window.removeEventListener('online', handleOnlineEvent);
            window.removeEventListener('offline', handleOfflineEvent);
        }
    });

    const handleKeyDown = (event) => {
        if (event.key === 'Tab' || event.key === 'Escape') {
            event.preventDefault();
            handleClose();
        }
    }

    const [loungePickerOpen, setLoungePickerOpen] = useState(false);

    const switchLounge = (nextLounge) => {
        onLoungeChange(nextLounge);
        handleClose();
    }

    return user ? (
        <>
            <IconButton onClick={handleOpen} ref={anchorRef}>
                <Avatar
                    src={user.photoURL}
                    alt={user.displayName}
                    title={user.displayName}
                    className={online ? '' : styles.disconnectedUser}
                />
                {!online && <div className={styles.status + ' ' + styles.statusDisconnected}>
                    <NetworkStatus online={false}/>
                </div>}
            </IconButton>
            <Popper onKeyDown={handleKeyDown} open={open} anchorEl={anchorRef.current} placement={'bottom'} style={{marginTop: '2px'}} transition>
                {({TransitionProps}) => (
                    <ClickAwayListener onClickAway={handleClose}>
                        <Grow {...TransitionProps}>
                            <Paper className={styles.menu}>
                                <div className={styles.location}>
                                    <span className={styles.username}>{user.displayName}</span>
                                    <span className={styles.email}>{user.email}</span>
                                    <div className={styles.lounge}>
                                        <SvgIcon component={StoreRounded}/>
                                        {lounge ? lounge.name : ""}
                                    </div>
                                </div>
                                <MenuList autoFocusItem={true} id="user-avatar-menu-grow">
                                    {lounges.length > 1 && <MenuItem key="pick-lounge" onClick={() => setLoungePickerOpen(!loungePickerOpen)}>
                                        <ListItemText>Switch lounge</ListItemText>
                                    </MenuItem>}
                                    {loungePickerOpen &&
                                        lounges.map(
                                            (item) => <MenuItem key={item.id} divider={item.isLast} onClick={() => switchLounge(item)}>
                                                <span className={(item?.id === lounge?.id) ? styles.loungeItemSelected : styles.loungeItem}>{item.name}</span>
                                            </MenuItem>
                                        )
                                    }
                                    <MenuItem key="reload-page" onClick={handleReload}>Reload app</MenuItem>
                                    <MenuItem key="sign-out" onClick={handleLogout}>Sign out</MenuItem>
                                </MenuList>
                            </Paper>
                        </Grow>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    ) : (<></>);
}

export default UserAvatar;
