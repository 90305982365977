import React from 'react';
import {handleGoogleLogin} from '../../hooks/useAuth';
import logo from '../../assets/hookamonk.svg';
import GoogleButton from 'react-google-button';
import styles from './Login.module.css';
import animations from '../animations/animations.module.css';

function Login() {
    return (
        <div className={styles.center}>
            <div className={styles.container}>
                <img className={[styles.logo, animations.glowing].join(" ")} src={logo} alt="Hookamonk logo"/>
                <GoogleButton onClick={handleGoogleLogin} type={"light"}/>
            </div>
        </div>
    );
}

export default Login;
