import firebase from 'firebase/compat/app';

const firebaseConfig = {
    apiKey: "AIzaSyAf2V5-0HhrE7UGtGiOVr3Unftip_ToutI",
    authDomain: "lounge.hookamonk.com",
    databaseURL: "https://session-monk.firebaseio.com",
    projectId: "session-monk",
    storageBucket: "session-monk.appspot.com",
    messagingSenderId: "557380557543",
    appId: "1:557380557543:web:99e068c36c2dd872a5a042",
    measurementId: "G-XQTPZRSDZ5"
};

export const firebaseApp = firebase.initializeApp(firebaseConfig);
export const documentId = () => firebase.firestore.FieldPath.documentId();
