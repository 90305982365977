import React from 'react';
import styles from './UpdateNotification.module.css';
import {useServiceWorker} from '../../hooks/useServiceWorker';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';

function UpdateNotification() {
    const {isUpdateAvailable, updateAssets} = useServiceWorker();

    return isUpdateAvailable ? (
        <div className={styles.container}>
            <Card variant={'elevation'} elevation={4} className={styles.card}>
                <span className={styles.title}>A new version is available!</span>
                <Button variant={'contained'} color={'primary'} onClick={updateAssets}>Update the App</Button>
            </Card>
        </div>
    ) : <></>;
}

export default UpdateNotification;
