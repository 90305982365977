import React, {createContext, useContext, useEffect, useState} from 'react';

const clockContext = createContext(Date.now());

function ClockProvider({children}) {
    const [clock, setClock] = useState(Date.now());

    useEffect(() => {
        const interval =  setInterval(() => {
            setClock(Date.now());
        }, 1000);

        return () => {
            clearInterval(interval);
        }
    }, [])

    return <clockContext.Provider value={clock}>{children}</clockContext.Provider>;
}

const useClock = () => useContext(clockContext);

export {useClock, ClockProvider}
